import React from 'react'

const Header = () => {
  return (
    <div style={{marginTop:'15px'}} className="titulo-conteudo"> <img className='image1' src="https://fariasbrito.com.br/assets/imgs/logo-colorida.webp" alt="Logo FB"/>
        <p className='p1' >Auditoria de Notas</p></div>

  )
}

export default Header